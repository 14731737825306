import { IRegisterFormData } from './RegisterForm.types';

export const InitRegistrationRequestPayload: IRegisterFormData = {
  title: '',
  firstname: '',
  lastname: '',
  email: '',
  address: '',
  phone: '',
  zip: '',
  city: '',
  toc: false,
  news: false
};
