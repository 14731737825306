import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { IQuestionBtnProps } from './QuestionBtn.types';
import { BtnList } from '../BtnList/BtnList';

export const QuestionBtn: React.FC<IQuestionBtnProps> = ({
  isLogged,
  isExam,
  disabled,
  onStartExam,
  currentCategory
}: IQuestionBtnProps) => {
  const { t } = useTranslation();

  const handleExamStart = useCallback(() => {
    if (onStartExam) {
      onStartExam(Date.now());
    }
  }, [onStartExam]);

  return (
    <BtnList>
      {isLogged ? (
        <>
          {!isExam && (
            <Button as={LinkWrapper} to={`/questions/${currentCategory}/all`} variant="secondary">
              {t('askContainer.learnNowBtn')}
            </Button>
          )}
          {isExam && (
            <Button disabled={disabled} variant="secondary" onClick={handleExamStart}>
              {t('examPage.startExam')}
            </Button>
          )}
        </>
      ) : (
        <>
          <Button variant="primary" as={LinkWrapper} to="/offers">
            {t('askContainer.dealsButton')}
          </Button>
          {isExam && (
            <Button disabled={disabled} variant="outline-primary" onClick={handleExamStart}>
              {t('examPage.startExam')}
            </Button>
          )}
          {!isExam && (
            <Button as={LinkWrapper} to={`/questions/${currentCategory}/demo`} variant="outline-primary">
              {t('askContainer.tryItForFreeButton')}
            </Button>
          )}
        </>
      )}
    </BtnList>
  );
};
