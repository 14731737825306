import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import styles from './HomeCards.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const HomeCards: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <div className={styles.promoCardList}>
          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.routinier.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.routinier.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a
                className="linkBtn"
                rel="noopener noreferrer"
                target="_blank"
                href={t('homePage.cards.routinier.link')}
              >
                {t('homePage.cards.more')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.vouchers.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.vouchers.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a
                className="linkBtn"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mobiliar.ch/versicherungen-und-vorsorge/angebote-fuer-junge-bis-26-jahre/sicher-fahren-100-franken-sparen?utm_campaign=Theorie24&utm_medium=button&utm_source=theorie24_de"
              >
                {t('homePage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.schoolCoupon.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.schoolCoupon.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <LinkWrapper className="linkBtn" to="/school">
                {t('homePage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.scooter.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.scooter.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a className="linkBtn" rel="noopener noreferrer" target="_blank" href={t('links.qualidrive')}>
                {t('homePage.cards.scooter.link')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HomeCards;
