import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import uuidv4 from 'uuid';
import useReactRouter from 'use-react-router';
import { SET_USER_SELECTED_CATEGORY } from '../../actions/user.action';
import { IRootState } from '../../reducer';
import { RulesGroupItem } from '../../ui-components/RulesGroupItem/RulesGroupItem';
import { CategoryList } from '../../components/CategoryList/CategoryList';
import { QuestionBtn } from '../../components/QuestionBtn/QuestionBtn';
import { ICategoryContainerProps } from './CategoryContainer.types';
import { ExamInfo } from '../../components/ExamInfo/ExamInfo';
import { IUserCategory } from '../../reducer/user.types';
import PageDescription from '../../ui-components/PageDescription/PageDescription';
import { CREATE_NEW_EXAM_REQUEST } from '../../actions/exam.action';
import { Request, RequestStatusType } from '../../reducer/fetch.types';
import { PENDING, SUCCESS } from '../../constants/store.constants';
import { Loader } from '../../ui-components/Loader/Loader';
import { CategoryType } from '../../reducer/question.types';
import { selectLanguage } from '../../reducer/user.selectors';
import { IUserCategoryList } from '../../reducer/category.types';
import { GET_CATEGORY_REQUEST } from '../../actions/category.action';
import { selectIsOfflineMode } from '../../reducer/auth.selectors';

const CategoryContainer: React.FC<ICategoryContainerProps> = ({ isExam }: ICategoryContainerProps) => {
  const { t } = useTranslation();
  const [selectedExamUuid, setSelectedExamUuid] = useState(0);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const selectedCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const createExamRequest: Request = useSelector(({ exam }: IRootState) => exam.createExamRequest);
  const categoryRequestStatus: RequestStatusType = useSelector(({ category }: IRootState) => category.categoryRequest);
  const userCategory: IUserCategoryList = useSelector(({ category }: IRootState) => category.category);
  const currentCategory: CategoryType = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const isOffline = useSelector(selectIsOfflineMode);
  const categoryInfo: IUserCategory | null = (userCategory && userCategory[selectedCategory]) || null;
  const duration = categoryInfo ? categoryInfo.period : null;
  const questionCount = categoryInfo ? categoryInfo.questionsCount : null;
  const pointCount = categoryInfo ? categoryInfo.maximumPoints : null;
  const currentLanguage = useSelector(selectLanguage);

  const handleClickDriveLicense = useCallback(
    (category: string) => {
      dispatch(SET_USER_SELECTED_CATEGORY({ category }));
    },
    [dispatch]
  );

  const handleStartExam = useCallback(
    (examStartTime: number) => {
      const uuid = uuidv4();
      dispatch(CREATE_NEW_EXAM_REQUEST({ examStartTime, uuid, duration, questionCount, pointCount }));
      setSelectedExamUuid(uuid);
    },
    [dispatch, duration, questionCount, pointCount]
  );

  useEffect(() => {
    if (createExamRequest.status === SUCCESS && selectedExamUuid) {
      history.push(`/${currentLanguage}/exam/${selectedExamUuid}`);
    }
  }, [createExamRequest, history, selectedExamUuid, currentLanguage]);

  useEffect(() => {
    if (!userCategory) {
      dispatch(GET_CATEGORY_REQUEST());
    }
  }, [dispatch, userCategory]);

  return (
    <>
      <Loader show={createExamRequest.status === PENDING || categoryRequestStatus === PENDING} />
      <RulesGroupItem>
        {!isLogged && (
          <PageDescription
            title={t(isExam ? 'examPage.exam' : 'askContainer.ask')}
            description={t(isExam ? 'examPage.description' : 'askContainer.description')}
          />
        )}
        {isLogged && (
          <PageDescription
            title={t(isExam ? 'examPage.exam' : 'askContainer.ask')}
            description={t(isExam ? 'myCockpitCategories.subtitle.exam' : 'myCockpitCategories.subtitle.ask')}
          />
        )}
        {isExam && <ExamInfo userCategory={userCategory} selectedCategory={selectedCategory} />}
        <CategoryList onClickDriveLicense={handleClickDriveLicense} selectedCategory={selectedCategory} />
        <QuestionBtn
          disabled={isOffline}
          currentCategory={currentCategory}
          isExam={isExam}
          isLogged={isLogged}
          onStartExam={handleStartExam}
        />
      </RulesGroupItem>
    </>
  );
};

export default CategoryContainer;
