import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { VideoCarousel } from '../../ui-components/VideoCarousel/VideoCarousel';
import ZoomImage from '../../ui-components/ZoomImage/ZoomImage';
import styles from './AboutPage.module.css';
import { IAboutPageProps } from './AboutPage.types';

const fragenImgSrc: string = '/content/tour/fragen.png';
const simulierenImgSrc: string = '/content/tour/simulieren.png';
const theorieImgSrc: string = '/content/tour/theorie.png';

const AboutPage: React.FC<IAboutPageProps> = ({ currentLanguage }: IAboutPageProps) => {
  const { t } = useTranslation();
  const isDe = currentLanguage === 'de';
  const showVideos = currentLanguage === 'de' || currentLanguage === 'fr';

  return (
    <div className={styles.aboutPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('aboutPage.title')}</PageTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle1')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageTextList}>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text1')}</p>
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text2')}</p>
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text3')}</p>
              </div>
            </div>
            <Button className={styles.aboutBtn} variant="primary" as={Link} to={`/${currentLanguage}/offers`}>
              {t('aboutPage.btn')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle2')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageTextList}>
              <div className={styles.aboutPageItem}>
                <ZoomImage src={fragenImgSrc} description={t('aboutPage.subTitle2')} />
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text4')}</p>
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text5')}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle3')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageTextList}>
              <div className={styles.aboutPageItem}>
                <ZoomImage src={simulierenImgSrc} description={t('aboutPage.subTitle3')} />
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text6')}</p>
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text7')}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle5')}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageTextList}>
              <div className={styles.aboutPageItem}>
                <ZoomImage src={theorieImgSrc} description={t('aboutPage.subTitle5')} />
              </div>
              <div className={styles.aboutPageItem}>
                <p className={styles.aboutPageText}>{t('aboutPage.text9')}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row hidden>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle6')}</div>
            {!isDe && <p>{t('aboutPage.onlyGermanVideo')}</p>}
          </Col>
          <Col>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle6')}</div>
          </Col>
          <Col />
        </Row>

        <Row hidden>
          <Col>
            <VideoCarousel
              videoList={['/content/videos/30066a.mp4', '/content/videos/30066a.mp4', '/content/videos/30066a.mp4']}
            />
          </Col>
          <Col>
            <VideoCarousel
              videoList={['/content/videos/30066a.mp4', '/content/videos/30066a.mp4', '/content/videos/30066a.mp4']}
            />
          </Col>
          <Col />
        </Row>

        {showVideos && (
          <Row>
            <Col md={6}>
              <div className={`${styles.aboutPageSubtitle} ${styles.videoTitle}`}>
                {t('aboutPage.theorie24Explanation')}
              </div>
              <div className={styles.videoContainer}>
                <iframe
                  src={t('aboutPage.theorie24ExplanationLink')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title={t('aboutPage.theorie24Explanation')}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className={`${styles.aboutPageSubtitle} ${styles.videoTitle}`}>{t('aboutPage.howToGetAccess')}</div>
              <div className={styles.videoContainer}>
                <iframe
                  src={t('aboutPage.howToGetAccessLink')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title={t('aboutPage.howToGetAccess')}
                />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default AboutPage;
