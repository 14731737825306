import { handleActions } from 'redux-actions';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { IQuestionState } from './question.types';
import { GET_QUESTION_FAILURE, GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS } from '../actions/question.action';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IQuestionState = {
  questionList: [],
  questionListRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_QUESTION_REQUEST]: () => ({
      ...defaultState,
      questionListRequest: { status: PENDING, error: null }
    }),
    [GET_QUESTION_FAILURE]: action => ({
      ...defaultState,
      questionListRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_QUESTION_SUCCESS]: (state, action) => ({
      ...state,
      questionListRequest: { status: SUCCESS, error: null },
      questionList: action.payload
    })
  },
  defaultState
);

export default reduces;
