import React, { FC, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import * as serviceWorker from '../../serviceWorker';
import styles from './ServiceWorkerUpdateNotification.module.css';

const ServiceWorkerUpdateNotification: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const { t } = useTranslation();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    ReactGA.event({
      category: 'appupdate',
      action: 'shown'
    });
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    if (waitingWorker) {
      ReactGA.event({
        category: 'appupdate',
        action: 'clicked'
      });
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
      window.location.reload(true);
    }
  };
  return (
    <Alert className={styles.notificationContainer} show={showReload} variant="warning">
      {t('serviceWorkerNotification.newVersionIsAvailable')}
      <Button onClick={reloadPage} variant="secondary">
        {t('serviceWorkerNotification.reload')}
      </Button>
    </Alert>
  );
};

export default ServiceWorkerUpdateNotification;
