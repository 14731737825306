import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { IRootState } from '../../reducer';
import { IQuestion } from '../../reducer/question.types';
import { IUserAnswer, IUserAnswerByTopic } from '../../reducer/answer.types';
import { IQuestionChooseModalContainerProps } from './QuestionChooseModalContainer.types';
import { CLEAR_ANSWER_BY_TOPIC_REQUEST } from '../../actions/answer.action';
import { GET_QUESTION_REQUEST } from '../../actions/question.action';
import { IUserExamAnswer, IUserExamAnswerByExamId } from '../../reducer/exam.types';
import { Request } from '../../reducer/fetch.types';
import { PENDING } from '../../constants/store.constants';
import { Loader } from '../../ui-components/Loader/Loader';
import styles from './QuestionChooseModalContainer.module.css';
import { QuestionLink } from '../../ui-components/QuestionLink/QuestionLink';

const getStatusCls = (answerList: IUserAnswerByTopic, question: IQuestion, isExam?: boolean): string => {
  if (isExam) {
    // in exam mode don't use question answers
    return '';
  }

  const answer: IUserAnswer | null =
    answerList && answerList[question.themeid] ? answerList[question.themeid][question.id] : null;

  switch (answer && answer.correct) {
    case true:
      return styles.examListItemCorrect;
    case false:
      return styles.examListItemIncorrect;
    default:
      return '';
  }
};
const getCheckedExamQuestionStatus = (
  answerList: IUserExamAnswerByExamId,
  question: IQuestion,
  examId: number
): boolean => {
  const answer: IUserExamAnswer | null = answerList[examId] ? answerList[examId][question.id] : null;
  return !!(answer && answer.selected && answer.selected.length);
};

const getExamCorrectStatus = (answerList: IUserExamAnswerByExamId, question: IQuestion, examId: number): boolean => {
  const answer: IUserExamAnswer | null = answerList[examId] ? answerList[examId][question.id] : null;
  return !!(answer && answer.correct);
};

const QuestionChooseModalContainer: React.FC<IQuestionChooseModalContainerProps> = ({
  onHide,
  topicId,
  onQuestionChange,
  isExam,
  finishedExam,
  defaultRoute,
  questionList,
  topicOpen,
  show
}: IQuestionChooseModalContainerProps) => {
  const questionsListFromStore: IQuestion[] = useSelector(({ question }: IRootState) => question.questionList);
  const { t } = useTranslation();
  const questionListRequest: Request = useSelector(({ question }: IRootState) => question.questionListRequest);
  const questions: IQuestion[] = questionList || questionsListFromStore;
  const examId = useSelector(({ exam }: IRootState) => exam.currentExamId);
  const questionAnswers: IUserAnswerByTopic = useSelector(({ answer }: IRootState) => answer.answerList);
  const examAnswers: IUserAnswerByTopic = useSelector(({ exam }: IRootState) => exam.examAnswerList);
  const dispatch = useDispatch();
  const {
    match: {
      params: { topic }
    }
  } = useReactRouter();

  const selectedTopic = topicId || topicId === 0 ? topicId : topic;

  useEffect(() => {
    if ((typeof topicId === 'number' || topicId === 'all') && show && !topicOpen) {
      dispatch(GET_QUESTION_REQUEST({ topic: topicId }));
    }
  }, [dispatch, topicId, show, topicOpen]);

  const handleAnswerClear = useCallback(() => {
    dispatch(CLEAR_ANSWER_BY_TOPIC_REQUEST({ topic: selectedTopic }));
  }, [dispatch, selectedTopic]);

  return (
    <>
      <Loader show={show && questionListRequest.status === PENDING} />

      <Modal
        className="modalQuestion"
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onHide}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={styles.examList}>
            {questions.map((question, id) => (
              <QuestionLink
                key={question.id}
                onQuestionChange={onQuestionChange}
                link={`${defaultRoute}/${id + 1}`}
                className={classNames(styles.examListItem, getStatusCls(questionAnswers, question, isExam), {
                  [styles.favorite]:
                    isExam &&
                    examAnswers[examId] &&
                    examAnswers[examId][question.id] &&
                    examAnswers[examId][question.id].favorite,
                  [styles.checked]: isExam && getCheckedExamQuestionStatus(examAnswers, question, examId),
                  [styles.examListItemCorrect]:
                    isExam && finishedExam && getExamCorrectStatus(examAnswers, question, examId),
                  [styles.examListItemIncorrect]:
                    isExam && finishedExam && !getExamCorrectStatus(examAnswers, question, examId)
                })}
              >
                <span className={styles.questionsNumbers}>{id + 1}</span>
              </QuestionLink>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!isExam && (
            <Button variant="secondary" onClick={handleAnswerClear}>
              {t('questionModal.btn')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionChooseModalContainer;
