import React from 'react';
import { Row, Col, Button, Jumbotron, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { ICockpitTopPanelProps } from './CockpitTopPanel.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { P3 } from '../../ui-components/P3/P3';
import styles from './CockpitTopPanel.module.css';
import { getPlanDateCountLabel } from '../../utils/utils';

const CockpitTopPanel: React.FC<ICockpitTopPanelProps> = ({
  userName,
  bestResult,
  onClickDriveLicense,
  selectedCategory,
  expirePlaneDateTime
}: ICockpitTopPanelProps) => {
  const { t } = useTranslation();
  const isAnyExamStarted = Boolean(bestResult && bestResult.questionCount);
  const availablePlanDays = getPlanDateCountLabel(expirePlaneDateTime);

  return (
    <div className={styles.cockpitTopPanel}>
      <Container>
        <Row>
          <Col>
            <div className={styles.jList}>
              <Jumbotron className={styles.jumbotron}>
                <PageTitle>{`${t('cockpitTopPanel.hi.title')}, ${userName}`}</PageTitle>
                <P3>{t('cockpitTopPanel.hi.subtitle1')}</P3>
                <P3>{t('cockpitTopPanel.hi.subtitle2')}</P3>
                <div className={styles.mobileJumbotron}>
                  <Jumbotron className={styles.jumbotron}>
                    <div className={styles.panelTitle}>{t('cockpitTopPanel.duration.title')}</div>
                    <div className={styles.daysContent}>
                      <div className={styles.daysValue}>{availablePlanDays}</div>
                      <div className={styles.daysLabel}>{t('cockpitTopPanel.duration.days')}</div>
                    </div>
                  </Jumbotron>
                </div>
                <div className={styles.cardFooter}>
                  <div className={styles.rulesList}>
                    <ButtonIcon
                      level="B"
                      selectedLevel={selectedCategory}
                      content={<i className="icon-car" />}
                      onClick={onClickDriveLicense}
                    />
                    <ButtonIcon
                      level="A,A1"
                      selectedLevel={selectedCategory}
                      content={<i className="icon-motorcycle" />}
                      onClick={onClickDriveLicense}
                    />
                    <ButtonIcon
                      level="F,G"
                      selectedLevel={selectedCategory}
                      content={<i className="icon-tractor" />}
                      onClick={onClickDriveLicense}
                    />
                    <ButtonIcon
                      level="M"
                      selectedLevel={selectedCategory}
                      content={<i className="icon-e-bike" />}
                      onClick={onClickDriveLicense}
                    />
                  </div>
                  <Button as={LinkWrapper} variant="secondary" to={`/questions/${selectedCategory}/all`}>
                    {t('cockpitTopPanel.hi.learn')}
                  </Button>
                </div>
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.jumbotronHeader}>
                  <div className={styles.jumbotronHeaderItem}>
                    <div className={styles.panelTitle}>{t('cockpitTopPanel.results.title')}</div>
                  </div>
                  {isAnyExamStarted && (
                    <div className={styles.panelDateList}>
                      <div className={styles.panelDate}>{moment(bestResult.startTime).format('DD.MM.YYYY')}</div>
                      <div className={styles.panelTime}>{moment(bestResult.startTime).format('HH:mm')}</div>
                    </div>
                  )}
                </div>
                {isAnyExamStarted && (
                  <div className={styles.panelCount}>
                    {`${bestResult.trueQuestionCount}/${bestResult.questionCount}`}
                  </div>
                )}
                {!isAnyExamStarted && <P3>{t('cockpitTopPanel.results.placeholder')}</P3>}
                <LinkWrapper to="#cockpitBottomPanel" className="linkBtn">
                  {isAnyExamStarted ? t('cockpitTopPanel.results.link') : t('cockpitTopPanel.results.startExamLink')}
                  <i className="icon-arrow" />
                </LinkWrapper>
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.panelTitle}>{t('cockpitTopPanel.duration.title')}</div>
                <div className={styles.daysContent}>
                  <div className={styles.daysValue}>{availablePlanDays}</div>
                  <div className={styles.daysLabel}>{t('cockpitTopPanel.duration.days')}</div>
                </div>
                <LinkWrapper className="linkBtn" to="/extend-offers">
                  {t('cockpitTopPanel.duration.link')}
                  <i className="icon-arrow" />
                </LinkWrapper>
              </Jumbotron>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitTopPanel;
