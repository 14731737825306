import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { selectSchool } from '../reducer/scool.selectors';
import { GET_SCHOOL_LIST_REQUEST } from '../actions/school.action';
import Pagination from '../components/Pagination/Pagination';
import { SchoolState } from '../reducer/school.types';
import { LinkWrapper } from '../ui-components/LinkWrapper/LinkWrapper';
import PlaceholderBox from '../components/PlaceholderBox/PlaceholderBox';
import { FAILURE, PENDING } from '../constants/store.constants';
import { SWISS_FRANC } from '../constants/currency';
import { getTeacherFullName } from '../utils/utils';
import styles from './SchoolTableContainer.module.css';

const SchoolTableContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentPage, schoolList, itemsCount, searchField, schoolListRequest }: SchoolState = useSelector(
    selectSchool
  );

  const handlePaginationClick = useCallback(
    (page: number) => {
      dispatch(GET_SCHOOL_LIST_REQUEST({ page, limit: 10, search: searchField }));
    },
    [dispatch, searchField]
  );

  return (
    <div className={styles.schoolTable}>
      <Container>
        <PlaceholderBox
          show={[FAILURE, PENDING].includes(schoolListRequest.status) && schoolList.length === 0}
          max-height="700px"
        >
          <div className={styles.tableHeader}>
            <div className={styles.tableHeaderItem} />
            <div className={styles.tableHeaderItem}>{t('drivingSchoolPage.thead.instructor')}</div>
            <div className={styles.tableHeaderItem}>{t('drivingSchoolPage.thead.address')}</div>
            <div className={styles.tableHeaderItem}>{t('drivingSchoolPage.thead.mobile')}</div>
            <div className={styles.tableHeaderItem}>{t('drivingSchoolPage.thead.cost')}</div>
            <div className={styles.tableHeaderItem} />
          </div>
          {schoolList.map(item => (
            <div className={styles.tableBodyRow} key={item.teacherId}>
              <div className={styles.tableBodyRowItem}>{item.rating}</div>
              <div className={styles.tableBodyRowItem}>
                {getTeacherFullName(item) && <div>{getTeacherFullName(item)}</div>}
                {item.teacherSchoolName && <div>{item.teacherSchoolName}</div>}
              </div>
              <div className={styles.tableBodyRowItem}>
                {item.teacherAddress}
                <br />
                {`${item.teacherZip} ${item.teacherCity}`}
              </div>
              <div className={styles.tableBodyRowItem}>{item.teacherMobile}</div>
              <div className={styles.tableBodyRowItem}>{`${SWISS_FRANC} ${item.teacherVoucherValue}`}</div>
              <div className={styles.tableBodyRowItem}>
                <LinkWrapper className={styles.linkBtn} to={`/school/${item.teacherId}`}>
                  <i className="icon-arrow" />
                </LinkWrapper>
              </div>
            </div>
          ))}
        </PlaceholderBox>
        {itemsCount > 10 && (
          <div className={styles.tableFooter}>
            <Pagination total={itemsCount} perPage={10} active={currentPage} onChange={handlePaginationClick} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default SchoolTableContainer;
