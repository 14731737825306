import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ILogoutModalProps } from './LogoutModal.types';
import styles from './LogoutModal.module.css';
import { P3 } from '../../ui-components/P3/P3';

const LogoutModal: React.FC<ILogoutModalProps> = ({ onHide, show, onLogout }: ILogoutModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal className={styles.modalLogout} animation show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{t('logoutModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <P3>{t('logoutModal.body')}</P3>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onHide}>
          {t('logoutModal.abort')}
        </Button>
        <Button className={styles.actionBtn} variant="secondary" type="submit" onClick={onLogout}>
          {t('logoutModal.buttonLabel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
