import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_MODAL_OPEN, LOGOUT_MODAL_OPEN } from '../actions/login.action';
import { IRootState } from '../reducer';
import { selectLanguage } from '../reducer/user.selectors';
import PromoContent from '../components/PromoContent/PromoContent';

const PromoContentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const currentLanguage = useSelector(selectLanguage);

  const handleLoginClick = useCallback(() => {
    dispatch(LOGIN_MODAL_OPEN());
  }, [dispatch]);

  const handleLogoutClick = useCallback(() => {
    dispatch(LOGOUT_MODAL_OPEN());
  }, [dispatch]);

  return (
    <PromoContent
      onLoginClick={handleLoginClick}
      onLogoutClick={handleLogoutClick}
      isLogged={isLogged}
      currentLanguage={currentLanguage}
    />
  );
};

export default PromoContentContainer;
