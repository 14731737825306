import React from 'react';
import { IPromoIconProps } from './PromoIcon.types';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './PromoIcon.module.css';

const vBest = imageAbsolutePath('front/best.svg');
const vTeacherDe = imageAbsolutePath('front/teacher_de.svg');
const vTeacherFr = imageAbsolutePath('front/teacher_fr.svg');
const vTeacherIt = imageAbsolutePath('front/teacher_it.svg');
const vTeacherEn = imageAbsolutePath('front/teacher_en.svg');
const vQuestionDe = imageAbsolutePath('front/question_de.svg');
const vQuestionFr = imageAbsolutePath('front/question_fr.svg');
const vQuestionIt = imageAbsolutePath('front/question_it.svg');
const vQuestionEn = imageAbsolutePath('front/question_en.svg');

const SRCS = {
  de: {
    '1': vBest,
    '2': vTeacherDe,
    '3': vQuestionDe
  },
  fr: {
    '1': vBest,
    '2': vTeacherFr,
    '3': vQuestionFr
  },
  it: {
    '1': vBest,
    '2': vTeacherIt,
    '3': vQuestionIt
  },
  en: {
    '1': vBest,
    '2': vTeacherEn,
    '3': vQuestionEn
  }
};

const Icon: React.FC<IPromoIconProps> = ({ src, alt, currentLanguage }: IPromoIconProps) => {
  const srcPath = SRCS[currentLanguage][src] || SRCS.de;
  return (
    <div className={styles.iconsListItem}>
      <img src={srcPath} alt={alt} />
    </div>
  );
};

export default Icon;
