import { createAction } from 'redux-actions';

export const CLEAR_EXAM = createAction('CLEAR_EXAM');

export const CHANGE_EXAM_ANSWER = createAction('CHANGE_EXAM_ANSWER');

export const SAVE_NEW_EXAM_REQUEST = createAction('SAVE_NEW_EXAM_REQUEST');
export const SAVE_NEW_EXAM_SUCCESS = createAction('SAVE_NEW_EXAM_SUCCESS');
export const SAVE_NEW_EXAM_FAILURE = createAction('SAVE_NEW_EXAM_FAILURE');

export const FINISH_EXAM_REQUEST = createAction('FINISH_EXAM_REQUEST');
export const FINISH_EXAM_SUCCESS = createAction('FINISH_EXAM_SUCCESS');
export const FINISH_EXAM_FAILURE = createAction('FINISH_EXAM_FAILURE');

export const CREATE_NEW_EXAM_REQUEST = createAction('CREATE_NEW_EXAM_REQUEST');
export const CREATE_NEW_EXAM_SUCCESS = createAction('CREATE_NEW_EXAM_SUCCESS');
export const CREATE_NEW_EXAM_FAILURE = createAction('CREATE_NEW_EXAM_FAILURE');

export const ADD_EXAM_ANSWER_REQUEST = createAction('ADD_EXAM_ANSWER_REQUEST');
export const ADD_EXAM_ANSWER_FAILURE = createAction('ADD_EXAM_ANSWER_FAILURE');
export const ADD_EXAM_ANSWER_SUCCESS = createAction('ADD_EXAM_ANSWER_SUCCESS');

export const GET_QUESTION_FOR_EXAM_REQUEST = createAction('GET_QUESTION_FOR_EXAM_REQUEST');
export const GET_QUESTION_FOR_EXAM_FAILURE = createAction('GET_QUESTION_FOR_EXAM_FAILURE');
export const GET_QUESTION_FOR_EXAM_SUCCESS = createAction('GET_QUESTION_FOR_EXAM_SUCCESS');

export const RECOVERY_EXAM_REQUEST = createAction('RECOVERY_EXAM_REQUEST');
export const RECOVERY_EXAM_SUCCESS = createAction('RECOVERY_EXAM_SUCCESS');
export const RECOVERY_EXAM_FAILURE = createAction('RECOVERY_EXAM_FAILURE');

export const UPDATE_ANSWER_POINT_REQUEST = createAction('UPDATE_ANSWER_POINT_REQUEST');
export const UPDATE_ANSWER_POINT_SUCCESS = createAction('UPDATE_ANSWER_POINT_SUCCESS');
export const UPDATE_ANSWER_POINT_FAILURE = createAction('UPDATE_ANSWER_POINT_FAILURE');

export const UPDATE_EXAM_RESULT_REQUEST = createAction('UPDATE_EXAM_RESULT_REQUEST');
export const UPDATE_EXAM_RESULT_SUCCESS = createAction('UPDATE_EXAM_RESULT_SUCCESS');
export const UPDATE_EXAM_RESULT_FAILURE = createAction('UPDATE_EXAM_RESULT_FAILURE');

export const GET_LAST_EXAM_REQUEST = createAction('GET_LAST_EXAM_REQUEST');
export const GET_LAST_EXAM_SUCCESS = createAction('GET_LAST_EXAM_SUCCESS');
export const GET_LAST_EXAM_FAILURE = createAction('GET_LAST_EXAM_FAILURE');

export const CLEAR_LAST_EXAM_DATA = createAction('CLEAR_LAST_EXAM_DATA');
