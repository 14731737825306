import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import stylesPage from '../OffersPage/OffersPage.module.css';
import OfferCardContainer from '../../containers/OfferCardContainer';
import VipCard from '../../components/VipCard/VipCard';
import VipCardAccordion from '../../components/VipCardAccordion/VipCardAccordion';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import styles from './ExtendOffersPage.module.css';
import { P3 } from '../../ui-components/P3/P3';

const ExtendOffersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container>
        <Row>
          <Col>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockItem}>
                <div className={styles.infoBlockContent}>
                  <PageTitle>{t('extendOffers.voucherBlock.title')}</PageTitle>
                  <div className={styles.extendPageText}>
                    <P3>{t('extendOffers.voucherBlock.text1')}</P3>
                    <P3>{t('extendOffers.voucherBlock.text2')}</P3>
                  </div>
                </div>
              </div>
              <div className={styles.infoBlockItem}>
                <div className={styles.infoBlockContent}>
                  <h3 className="pageSubtitle">{t('homePage.vipCard.title')}</h3>
                  <VipCard />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={cx(stylesPage.offerPageCard, styles.offersBlock)}>
        <OfferCardContainer />
      </div>
      <Container>
        <Row>
          <Col>
            <div className={styles.vipCardMobile}>
              <h3 className="pageSubtitle">{t('homePage.vipCard.title')}</h3>
              <VipCardAccordion />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExtendOffersPage;
