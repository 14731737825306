import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import CockpitNotLoggedTopContainer from '../../containers/CockpitNotLoggedTopContainer';
import CockpitCategoriesContainer from '../../containers/CockpitCategoriesContainer';
import Ads from '../../components/Ads/Ads';
import image from '../../img/myCockpitPage.jpg';
import Image from '../../ui-components/Image/Image';
import styles from './CockpitNotLoggedPage.module.css';

const CockpitNotLoggedPage: React.FC = () => {
  return (
    <>
      <div className={styles.CockpitNotLoggedPage}>
        <Container>
          <Row>
            <Col>
              <div className={styles.rulesGroupContent}>
                <div className={styles.imgContainer}>
                  <Image src={image} width="100%" />
                </div>
                <CockpitNotLoggedTopContainer />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Ads placement="mobile" />
      <CockpitCategoriesContainer />
      <Ads placement="desktop" />
    </>
  );
};

export default CockpitNotLoggedPage;
