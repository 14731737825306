import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import Avatar from '../../ui-components/Avatar/Avatar';
import { SchoolInfoModalProps } from './SchoolInfoModalContainer.types';
import { selectCurrentSchool, selectSchool } from '../../reducer/scool.selectors';
import { GET_SCHOOL_BY_ID_REQUEST } from '../../actions/school.action';
import { School, SchoolState } from '../../reducer/school.types';
import { PENDING } from '../../constants/store.constants';
import { Loader } from '../../ui-components/Loader/Loader';
import { TEACHER_ID } from '../../constants/storageKeys';
import { selectLanguage } from '../../reducer/user.selectors';
import styles from './SchoolInfoModalContainer.module.css';
import { formatTextValue, isSSR } from '../../utils/utils';

const SchoolInfoModalContainer: React.FC<SchoolInfoModalProps> = ({ teacherId, onHide }: SchoolInfoModalProps) => {
  const { t } = useTranslation();
  const { history } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const currentSchool: School = useSelector(selectCurrentSchool);
  const { currentSchoolRequest }: SchoolState = useSelector(selectSchool);
  useEffect(() => {
    if (teacherId) {
      dispatch(GET_SCHOOL_BY_ID_REQUEST(teacherId));
    }
  }, [dispatch, teacherId]);
  const handleClick = () => {
    if (!isSSR()) {
      window.localStorage.setItem(TEACHER_ID, String(currentSchool.teacherId));
    }
    onHide();
    history.push(`/${currentLanguage}/offers`);
  };

  if (teacherId === null) {
    return null;
  }

  if (currentSchoolRequest.status === PENDING) {
    return <Loader show />;
  }

  return (
    <Modal show={!!currentSchool} onHide={onHide} size="sm" dialogClassName={styles.root}>
      <Modal.Header closeButton className={styles.modalHeader} />
      <Modal.Body className={styles.modalBody}>
        {currentSchool && (
          <div>
            <div className={cx(styles.infoSection, styles.generalInfoSection)}>
              <div className={styles.avatarBox}>
                <Avatar alt={currentSchool.teacherFirstName} />
              </div>
              <div className={styles.generalInfoBox}>
                <p className={styles.titleText}>
                  {`${currentSchool.teacherFirstName} ${currentSchool.teacherLastName}`}
                </p>
                <p className={styles.subTitleText}>{currentSchool.teacherSchoolName}</p>
              </div>
            </div>
            <div className={styles.infoSection}>
              <p className={styles.labelText}>{t('drivingSchoolPage.teacher.address')}</p>
              <p className={styles.infoText}>
                {currentSchool.teacherAddress}
                <br />
                {`${currentSchool.teacherZip} ${currentSchool.teacherCity}`}
              </p>
            </div>
            <div className={cx(styles.infoSection, styles.nestedSection)}>
              {currentSchool.teacherPhone.trim() && (
                <div>
                  <p className={styles.labelText}>{t('drivingSchoolPage.teacher.phone')}</p>
                  <p className={styles.infoText}>{currentSchool.teacherPhone}</p>
                </div>
              )}
              {currentSchool.teacherMobile.trim() && (
                <div>
                  <p className={styles.labelText}>{t('drivingSchoolPage.teacher.mobile')}</p>
                  <p className={styles.infoText}>{currentSchool.teacherMobile}</p>
                </div>
              )}
            </div>
            {currentSchool.teacherEmail.trim() && (
              <div className={styles.infoSection}>
                <p className={styles.labelText}>{t('drivingSchoolPage.teacher.email')}</p>
                <p className={styles.infoText}>{currentSchool.teacherEmail}</p>
              </div>
            )}
            <div className={styles.infoSection}>
              <p className={styles.labelText}>{t('drivingSchoolPage.teacher.cost')}</p>
              <p className={styles.infoText}>{formatTextValue(currentSchool.teacherVoucherValue.toString())}</p>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.controlsContainer}>
        <Button variant="secondary" onClick={handleClick}>
          {t('drivingSchoolPage.teacher.button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SchoolInfoModalContainer;
