import fetch, { IApiFailureResponse } from './fetch';
import { IUserPlanExtensionRequestPayload, IUserPlanExtensionSuccessResponse, IUserProfileApi } from './user.types';
import { db } from '../config/firebase.config';

export const getProfile = (): IUserProfileApi => {
  return fetch('GET', '/customer');
};

export const getFirebaseProfile = async userId => {
  const snapshot = await db
    .collection('user')
    .doc(userId)
    .get();

  const userData = snapshot.data();

  return userData
    ? {
        address: userData.address,
        email: userData.email,
        firstName: userData.firstName,
        language: userData.language,
        lastName: userData.lastName,
        mobile: userData.mobile,
        phone: userData.phone,
        drivingInstructor: userData.drivingInstructor
      }
    : null;
};

export const userPlanExtension = (
  payload: IUserPlanExtensionRequestPayload
): Promise<IUserPlanExtensionSuccessResponse | IApiFailureResponse> => fetch('POST', '/user/renew', payload);

export const setUserLanguageApi = (language: string) => {
  return fetch('PATCH', '/customer', { language });
};
