import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const SeoLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <Col>
      <LinkWrapper to="/autopruefung-theorie">{t('seolinks.link1')}</LinkWrapper>
      {' | '}
      <LinkWrapper to="/mofa-pruefung">{t('seolinks.link2')}</LinkWrapper>
      {' | '}
      <LinkWrapper to="/rollerpruefung">{t('seolinks.link3')}</LinkWrapper>
      {' | '}
      <LinkWrapper to="/theoriepruefung-lernen">{t('seolinks.link4')}</LinkWrapper>
    </Col>
  );
};

export default SeoLinks;
