import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { IRootState } from '../reducer';
import { IChapters } from '../reducer/theory.types';
import { TheoryTopic } from '../components/TheoryTopic/TheoryTopic';
import { selectLanguage } from '../reducer/user.selectors';
import styles from './TheoryTopicsListContainer.module.css';

const TheoryTopicsListContainer: React.FC = () => {
  const chapters = useSelector(({ theory }: IRootState) => theory.theory.chapters);
  const { history } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);

  const handleChangePathname = useCallback(
    (pathname: string) => {
      history.push(`/${currentLanguage}${pathname}`);
    },
    [history, currentLanguage]
  );

  return (
    <div className={styles.topicList}>
      {chapters.map((chapter: IChapters, index: number) => (
        <TheoryTopic
          onChangePathname={handleChangePathname}
          pathname={`/theory/topics/${chapter.page}`}
          content={`${index + 1}. ${chapter.title}`}
          key={chapter.title}
        />
      ))}
    </div>
  );
};

export default TheoryTopicsListContainer;
