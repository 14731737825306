import React from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS, isAndroid } from 'react-device-detect';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './VipContent.module.css';
import VipCard from '../VipCard/VipCard';
import Market from '../Market/Market';

const google = imageAbsolutePath('googleBtn.svg');
const apple = imageAbsolutePath('appleBtn.svg');

const VipContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sectionVipContent}>
      <div className={styles.sectionVipItem}>
        <div className="h3">{t('homePage.vipCard.title')}</div>
        <VipCard />
      </div>
      {!isIOS && !isAndroid && (
        <div className={styles.sectionVipItem}>
          <div className="h3">{t('homePage.readyToLearn.title')}</div>
          <p className="pageText">{t('homePage.readyToLearn.text')}</p>
          <div className={styles.storeBtnList}>
            <Market src={google} alt="google" />
            <Market src={apple} alt="apple" />
          </div>
        </div>
      )}
    </div>
  );
};

export default VipContent;
