import React from 'react';
import { getFBShareLink, getLiShareLink } from '../../utils/utils';
import { IShareProps } from './Share.types';
import styles from './Share.module.css';

const Share: React.FC<IShareProps> = ({ currentLanguage }: IShareProps) => (
  <div className={styles.resultShareList}>
    <div className={styles.resultShareItem}>
      <a rel="noopener noreferrer" target="_blank" href={getFBShareLink(currentLanguage)}>
        <i className="icon-facebook" />
      </a>
    </div>
    <div className={styles.resultShareItem}>
      <a rel="noopener noreferrer" target="_blank" href={getLiShareLink(currentLanguage)}>
        <i className="icon-linkedine" />
      </a>
    </div>
  </div>
);

export default Share;
