import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLogin } from '../../reducer/login.selectors';
import CockpitLoggedPage from '../CockpitLoggedPage/CockpitLoggedPage';
import HomePage from '../HomePage/HomePage';

const MainPage: FC = () => {
  const isLogged: boolean = useSelector(selectIsLogin);

  if (isLogged) {
    return <CockpitLoggedPage />;
  }

  return <HomePage />;
};

export default MainPage;
