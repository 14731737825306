import React, { useCallback, useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { GET_TOPICS_REQUEST } from '../../actions/topic.action';
import { CLEAR_ANSWER_BY_TOPIC_REQUEST, GET_ANSWER_FROM_FIREBASE_REQUEST } from '../../actions/answer.action';
import { TopicsAttributes } from '../../reducer/topic.types';
import { IRootState } from '../../reducer';

import TopicProgress from '../../components/TopicProgress/TopicProgress';
import { IUserAnswer } from '../../reducer/user.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { selectLanguage } from '../../reducer/user.selectors';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import useDeviceSizes from '../../hooks/useDeviceSizes';
import styles from './QuestionsByTopicContainer.module.css';
import { P3 } from '../../ui-components/P3/P3';

const QuestionsByTopicContainer: React.FC = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const topics = useSelector(({ topic }: IRootState) => topic.topics);
  const language = useSelector(selectLanguage);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const dispatch = useDispatch();
  const category = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const { history } = useReactRouter();
  const userAnswers: IUserAnswer = useSelector(({ answer: { answerList } }: IRootState) => {
    return answerList;
  });
  const { width } = useDeviceSizes();
  const isMobileMode = width <= 991;

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  const handleAnswerClear = useCallback(() => {
    dispatch(CLEAR_ANSWER_BY_TOPIC_REQUEST({ topic: 'all' }));
  }, [dispatch]);

  const handleExpandBtnClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [setIsExpanded, isExpanded]);

  useEffect(() => {
    dispatch(GET_TOPICS_REQUEST({ category, language }));
  }, [dispatch, category, language]);

  useEffect(() => {
    dispatch(GET_ANSWER_FROM_FIREBASE_REQUEST());
  }, [dispatch, category]);

  const isShowTopicBars = useMemo(() => {
    if (!isLogged) {
      return false;
    }
    return !(isMobileMode && !isExpanded);
  }, [isLogged, isMobileMode, isExpanded]);

  const hasAnswers = Object.keys(userAnswers).length > 0;

  return (
    <div className={styles.questionSection}>
      <Container>
        <Row>
          <Col>
            <div className={styles.questionsByTopic}>
              <PageTitle className={styles.pageTitle}>{t('questionsByTopicContainer.aboutTopics')}</PageTitle>
              <div
                className={cx(styles.questionsByTopicList, {
                  [styles.mobileMode]: isMobileMode,
                  [styles.expanded]: isExpanded
                })}
              >
                {topics.map((topic: TopicsAttributes) => (
                  <div className={styles.questionsByTopicListItem} key={topic.id}>
                    <div>
                      {isLogged &&
                        (topic.id < 0 ? (
                          <LinkWrapper className={styles.questionsLink} to={`/questions/${currentCategory}/all`}>
                            {topic.title}
                          </LinkWrapper>
                        ) : (
                          <LinkWrapper
                            className={styles.questionsLink}
                            to={`/questions/${currentCategory}/${topic.id}`}
                          >
                            {topic.title}
                          </LinkWrapper>
                        ))}
                      {!isLogged &&
                        (topic.id < 0 ? (
                          <LinkWrapper className={styles.questionsLink} to={`/questions/${currentCategory}/demo`}>
                            {topic.title}
                          </LinkWrapper>
                        ) : (
                          <div className={styles.questionsLink}>{topic.title}</div>
                        ))}
                      {isShowTopicBars && (
                        <TopicProgress
                          onQuestionChange={handleQuestionChange}
                          topic={topic}
                          userAnswers={userAnswers}
                          currentLanguage={language}
                          currentCategory={currentCategory}
                        />
                      )}
                    </div>
                  </div>
                ))}
                {hasAnswers && (
                  <div className={styles.questionsByTopicListItem}>
                    <Button variant="secondary" onClick={handleAnswerClear}>
                      {t('questionsByTopicContainer.clearAll')}
                    </Button>
                  </div>
                )}
              </div>
              {isMobileMode && (
                <div className={styles.extendControlContainer}>
                  <Button variant="link" className={styles.expandBtn} onClick={handleExpandBtnClick}>
                    {isExpanded
                      ? t('questionsByTopicContainer.expandBlock.showLess')
                      : t('questionsByTopicContainer.expandBlock.showMore')}
                    <span className={cx(styles.expandStatusIcon, { [styles.expanded]: isExpanded })} />
                  </Button>
                </div>
              )}
              <div className={styles.questionsTopicText}>
                <P3>{t('questionsByTopicContainer.descriptionLeft')}</P3>
                <P3>{t('questionsByTopicContainer.descriptionCenter')}</P3>
                <P3>{t('questionsByTopicContainer.descriptionRight')}</P3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuestionsByTopicContainer;
