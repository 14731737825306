import { createAction } from 'redux-actions';

export const SET_DEVICE_UID = createAction('SET_DEVICE_UID');
export const SET_DEFAULT_AUTH = createAction('SET_DEFAULT_AUTH');
export const GET_TOKEN_REQUEST = createAction('GET_TOKEN_REQUEST');
export const GET_TOKEN_SUCCESS = createAction('GET_TOKEN_SUCCESS');
export const GET_TOKEN_FAILURE = createAction('GET_TOKEN_FAILURE');
export const SET_DEVICE_CHECKED = createAction('SET_DEVICE_CHECKED');
export const FIREBASE_AUTH_REQUEST = createAction('FIREBASE_AUTH_REQUEST');
export const FIREBASE_AUTH_SUCCESS = createAction('FIREBASE_AUTH_SUCCESS');
export const FIREBASE_AUTH_FAILURE = createAction('FIREBASE_AUTH_FAILURE');
export const SET_OFFLINE_MODE_STATE = createAction('SET_OFFLINE_MODE_STATE');
export const UPDATE_DEVICE_UID_REQUEST = createAction('UPDATE_DEVICE_UID_REQUEST');
export const UPDATE_DEVICE_UID_SUCCESS = createAction('UPDATE_DEVICE_UID_SUCCESS');
export const UPDATE_DEVICE_UID_FAILURE = createAction('UPDATE_DEVICE_UID_FAILURE');
