import { handleActions } from 'redux-actions';
import { IUserState } from './user.types';
import {
  SET_USER_SELECTED_TOPIC,
  SET_USER_LANGUAGE_SUCCESS,
  SET_USER_SELECTED_QUESTION,
  SET_USER_SELECTED_CATEGORY,
  SET_USER_QUESTION_LANGUAGE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  SET_DEFAULT_USER_PROFILE,
  SET_USER_STATISTICS,
  CLEAR_USER,
  GET_USER_EXAM_RESULTS_SUCCESS,
  GET_USER_EXAM_RESULTS_REQUEST,
  GET_USER_EXAM_RESULTS_FAILURE,
  GET_PROFILE_FROM_FIREBASE_REQUEST,
  GET_PROFILE_FROM_FIREBASE_FAILURE,
  GET_PROFILE_FROM_FIREBASE_SUCCESS,
  PLAN_EXTENSION_REQUEST,
  PLAN_EXTENSION_SUCCESS,
  PLAN_EXTENSION_FAILURE,
  SET_LANGUAGE_TOGGLE_STATE,
  SET_SELECTED_QUESTION_LANGUAGE,
  SET_USER_LANGUAGE_REQUEST,
  SET_USER_LANGUAGE_FAILURE
} from '../actions/user.action';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IUserState = {
  profile: {
    questionLanguage: 'de',
    currentCategory: 'B',
    language: 'de',
    selectedQuestionId: 0
  },
  examResults: {
    lastExams: [],
    bestResult: {
      startTime: 0,
      trueQuestionCount: 0,
      questionCount: 0
    }
  },
  planExtensionRequest: defaultRequestStatus,
  userRequest: defaultRequestStatus,
  userFbRequest: defaultRequestStatus,
  examResultRequest: defaultRequestStatus,
  languageRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_PROFILE_FROM_FIREBASE_REQUEST]: (state: IUserState): IUserState => ({
      ...state,
      profile: {
        ...defaultState.profile,
        language: state.profile.language
      },
      userFbRequest: { status: PENDING, error: null }
    }),
    [GET_PROFILE_FROM_FIREBASE_FAILURE]: (state, action) => ({
      ...state,
      userFbRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_PROFILE_FROM_FIREBASE_SUCCESS]: (state, action) => ({
      ...state,
      profile: { ...state.profile, ...action.payload },
      userFbRequest: { status: SUCCESS, error: null }
    }),
    [SET_USER_LANGUAGE_REQUEST]: (state: IUserState): IUserState => ({
      ...state,
      languageRequest: { status: PENDING, error: null }
    }),
    [SET_USER_LANGUAGE_SUCCESS]: (state: IUserState, action): IUserState => ({
      ...state,
      profile: { ...state.profile, language: action.payload.language },
      languageRequest: { status: SUCCESS, error: null }
    }),
    [SET_USER_LANGUAGE_FAILURE]: (state: IUserState, payload): IUserState => ({
      ...state,
      languageRequest: { status: FAILURE, error: payload }
    }),
    [SET_USER_SELECTED_CATEGORY]: (state, action) => ({
      ...state,
      profile: { ...state.profile, currentCategory: action.payload.category }
    }),
    [SET_USER_QUESTION_LANGUAGE_SUCCESS]: (state, action) => ({
      ...state,
      profile: { ...state.profile, questionLanguage: action.payload.language }
    }),
    [SET_USER_SELECTED_TOPIC]: (state, action) => ({
      ...state,
      user: { ...state.profile, questionLanguage: action.payload.language }
    }),
    [SET_USER_SELECTED_QUESTION]: (state, action) => ({
      ...state,
      user: { ...state.profile, selectedQuestionId: action.payload.selectedQuestionId }
    }),
    [GET_USER_PROFILE_REQUEST]: state => ({
      ...state,
      userRequest: { status: PENDING, error: null }
    }),
    [GET_USER_PROFILE_FAILURE]: (state, action) => ({
      ...state,
      userRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_USER_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      profile: { ...state.profile, ...action.payload },
      userRequest: { status: SUCCESS, error: null }
    }),
    [GET_USER_EXAM_RESULTS_REQUEST]: state => ({
      ...state,
      examResultRequest: { status: PENDING, error: null }
    }),
    [GET_USER_EXAM_RESULTS_SUCCESS]: (state, action) => ({
      ...state,
      examResultRequest: { status: SUCCESS, error: null },
      examResults: { ...action.payload }
    }),
    [GET_USER_EXAM_RESULTS_FAILURE]: (state, action) => ({
      ...state,
      examResultRequest: { status: FAILURE, error: action.payload }
    }),
    [SET_DEFAULT_USER_PROFILE]: state => ({
      ...state,
      profile: defaultState.profile
    }),
    [SET_USER_STATISTICS]: (state, action) => ({
      ...state,
      currentCategoryStatistics: action.payload
    }),
    [CLEAR_USER]: () => ({
      ...defaultState,
      userFbRequest: defaultState.userFbRequest
    }),
    [PLAN_EXTENSION_REQUEST]: state => ({
      ...state,
      planExtensionRequest: { status: PENDING, error: null }
    }),
    [PLAN_EXTENSION_SUCCESS]: state => ({
      ...state,
      planExtensionRequest: { status: SUCCESS, error: null }
    }),
    [PLAN_EXTENSION_FAILURE]: (state, action) => ({
      ...state,
      planExtensionRequest: { status: FAILURE, error: action.payload }
    }),
    [SET_LANGUAGE_TOGGLE_STATE]: (state, action) => ({
      ...state,
      profile: {
        ...state.profile,
        toggleLngState: action.payload.toggleState
      }
    }),
    [SET_SELECTED_QUESTION_LANGUAGE]: (state, action) => ({
      ...state,
      profile: {
        ...state.profile,
        selectedQuestionLang: action.payload
      }
    })
  },
  defaultState
);

export default reduces;
