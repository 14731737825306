import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import styles from './AsaPage.module.css';
import { P3 } from '../../ui-components/P3/P3';

// Asa(Urheberrechtshinweis asa)
const AsaPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.asaPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle className={styles.asaPageTitle}>{t('asa.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.asaPageContent}>
              <div className={styles.asaPageItem}>
                <P3>{t('asa.content1')}</P3>
              </div>
              <div className={styles.asaPageItem}>
                <P3>{t('asa.content2')}</P3>
              </div>
              <div className={styles.asaPageItem}>
                <P3>{t('asa.content3.text1')}</P3>
                <P3>{t('asa.content3.text2')}</P3>
                <P3>{t('asa.content3.text3')}</P3>
                <P3>{t('asa.content3.text4')}</P3>
                <P3>{t('asa.content3.text5')}</P3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AsaPage;
