import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './HomeCarousel.module.css';

const germany = imageAbsolutePath('lang/Germany.svg');
const france = imageAbsolutePath('lang/France.svg');
const italy = imageAbsolutePath('lang/Italy.svg');
const spain = imageAbsolutePath('lang/Spain.svg');
const uk = imageAbsolutePath('lang/UK.svg');
const turkey = imageAbsolutePath('lang/Turkey.svg');
const portugal = imageAbsolutePath('lang/Portugal.svg');
const croatia = imageAbsolutePath('lang/Croatia.svg');
const albania = imageAbsolutePath('lang/Albania.svg');

const HomeCarousel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Carousel className={styles.homeSlider}>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.fromCHF.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.fromCHF.text')}</p>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.everythingLogin.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.everythingLogin.text')}</p>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.asaQuestions.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.asaQuestions.text')}</p>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.lngRules.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.lngRules.textLng')}</p>
        <ul className={styles.langList}>
          <li>
            <img src={germany} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={france} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={italy} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={uk} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={spain} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={turkey} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={portugal} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={croatia} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={albania} width={32} height={32} alt="lang" />
          </li>
        </ul>
        <p className={styles.sliderText}>{t('homePage.slides.lngRules.textRules')}</p>
        <ul className={styles.langList}>
          <li>
            <img src={germany} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={france} width={32} height={32} alt="lang" />
          </li>
          <li>
            <img src={italy} width={32} height={32} alt="lang" />
          </li>
        </ul>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
