import { memo, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { selectUseruserRequest, selectLanguage } from '../../reducer/user.selectors';
import { SUCCESS, PENDING } from '../../constants/store.constants';
import { IUserLoginSuccessContainerProps } from './UserLoginSuccessContainer.types';
import { usePrevious } from '../../hooks/usePrevious';

export const UserLoginSuccessContainer: FC<IUserLoginSuccessContainerProps> = memo(
  function UserLoginSuccessContainerMemo(props: IUserLoginSuccessContainerProps) {
    const { history, match } = props;
    const { locale, path = '' } = match.params;
    const { status: userRequestStatus } = useSelector(selectUseruserRequest);
    const currentLanguage = useSelector(selectLanguage);
    const prevUserRequestStatus = usePrevious(userRequestStatus);

    // After getting user info - check language.
    // Apply the language if locale and user language different
    useEffect(() => {
      if (prevUserRequestStatus === PENDING && userRequestStatus === SUCCESS && locale !== currentLanguage) {
        history.push(`/${currentLanguage}/${path}`);
      }
    }, [userRequestStatus, currentLanguage, history, locale, path, prevUserRequestStatus]);

    return null;
  }
);
