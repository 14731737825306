import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ModalPlanExpired from '../../components/ModalPlanExpired/ModalPlanExpired';
import { selectIsLogin } from '../../reducer/login.selectors';
import { selectExpirePlaneDateTime } from '../../reducer/user.selectors';
import { isPlanExpired } from '../../utils/utils';
import { LOGOUT_REQUEST, LOGOUT_MODAL_HIDE } from '../../actions/login.action';

const ModalPlanExpiredContainer = React.memo(() => {
  const isLogged = useSelector(selectIsLogin);
  const expirePlaneDateTime = useSelector(selectExpirePlaneDateTime);
  const dispatch = useDispatch();
  const isExpired = isPlanExpired(expirePlaneDateTime);
  const [isShowed, setIsShowed] = useState<boolean>(false);
  const handleHideHandler = useCallback(
    (logout?: boolean) => {
      if (logout) {
        setIsShowed(false);
        dispatch(LOGOUT_REQUEST());
        dispatch(LOGOUT_MODAL_HIDE());
      } else {
        setIsShowed(true);
      }
    },
    [dispatch, setIsShowed]
  );

  useEffect(() => {
    if (isExpired && isLogged) {
      setIsShowed(false);
    }
  }, [isLogged, isExpired, setIsShowed]);

  if (!isLogged) {
    return null;
  }

  return (
    <>
      <ModalPlanExpired show={isExpired && !isShowed} onHide={handleHideHandler} />
    </>
  );
});

export default ModalPlanExpiredContainer;
