import React from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router';
import useReactRouter from 'use-react-router';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import { SUCCESS } from '../constants/store.constants';
import { selectLanguage } from '../reducer/user.selectors';
import { IPaymentRedirectQueryParams } from '../pages/PaymentRedirectPage/PaymentRedirectPage.types';

const PlanExtensionFinishContainer: React.FC<RouteComponentProps> = ({ location }) => {
  const { history } = useReactRouter();
  const currentLanguage: string = useSelector(selectLanguage);
  const { isLogged }: LoginState = useSelector(selectLogin);
  const { planExtend }: IPaymentRedirectQueryParams = queryString.parse(location.search);

  if (!planExtend || !isLogged) {
    return null;
  }

  if (planExtend === SUCCESS) {
    history.replace(`/${currentLanguage}/confirmed-plan-extension`);

    return null;
  }
  return <Redirect to="/" />;
};

export default PlanExtensionFinishContainer;
