import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/api';
import { School } from '../reducer/school.types';
import { IQuestion } from '../reducer/question.types';
import { IUserAnswer } from '../reducer/user.types';
import i18n from '../i18n-local';
import { defaultLanguage } from '../locales/locale';

export const getRandomListFromArr = (arr, count: number) => {
  arr.sort(() => Math.random() - 0.5);
  return arr.length > count ? arr.splice(0, count) : arr;
};

export const randomInteger = (min: number, max: number): number => {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1));
};

export const isSSR = () => typeof window === 'undefined';

export const getFBCategoryName = (category: string): string => {
  switch (category) {
    case 'B':
    case 'A,A1':
      return 'BAA1';
    case 'F,G':
      return 'FG';
    default:
      return category;
  }
};

export const getTheoryImage = (id: string): string => `/content/theory/${id}.jpg`;

const separateLinePattern = /\n{1}/;

export const splitTextByLines = (text: string): string[] => text.split(separateLinePattern);

export const getTheoryContentImgAnchorIndex = (text: string): string | null => {
  if (text.includes('[IMAGE') && text.includes(']')) {
    return text
      .replace('[IMAGE', '')
      .replace(']', '')
      .trim();
  }
  return null;
};
const separator = '<|+|>';
const imgPlaceholderPattern = /\[IMAGE[0-9]+]/g;

export const splitTextContentWithImgToChunks = (text: string): string[] =>
  text
    .replace(imgPlaceholderPattern, `${separator}$&${separator}`)
    .split(separator)
    .map(item => item.trim())
    .filter(Boolean);

const sharedUrl: string = 'https://app.theorie24.ch/';

export const getFBShareLink = (lang: string): string =>
  `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}${lang}/`;
export const getLiShareLink = (lang: string): string =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${sharedUrl}${lang}/`;

export const formatTextValue = (value: string, emptyPlaceholder: string = '-'): string => {
  return value.trim() ? value : emptyPlaceholder;
};

export const scrollToAnchor = (id: string, args?: ScrollIntoViewOptions): void => {
  const newId = id.startsWith('#') ? id.slice(1) : id;
  const { block = 'start', behavior = 'smooth' } = args || {};
  const element: HTMLElement | null = document.getElementById(newId);

  if (element) {
    element.scrollIntoView({ block, behavior });
  }
};

export const isPlanExpired = (expirePlaneDateTime?: string | null): boolean => {
  if (!expirePlaneDateTime) {
    return false;
  }

  const expiredMoment = moment(expirePlaneDateTime, API_DATE_FORMAT);
  if (!expiredMoment.isValid()) {
    return false;
  }

  const now = moment();
  return now.isAfter(expiredMoment);
};

export const getPlanDateCountLabel = (expirePlaneDateTime?: string, expiredLabel: string = '0'): string => {
  if (expirePlaneDateTime) {
    if (isPlanExpired(expirePlaneDateTime)) {
      return expiredLabel;
    }

    return String(moment(expirePlaneDateTime, API_DATE_FORMAT).diff(moment(), 'days'));
  }

  return expiredLabel;
};

export const getTeacherFullName = (school: School): string => {
  const teacherFirstName = school.teacherFirstName ? school.teacherFirstName.trim() : '';
  const teacherLastName = school.teacherLastName ? school.teacherLastName.trim() : '';
  return `${teacherFirstName} ${teacherLastName}`.trim();
};

export const getTextMessageFromRequestError = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && error !== null && error.message) {
    return error.message;
  }
  return '';
};

export const triggerMenuCollapse = () => {
  const toggleButton: HTMLButtonElement | null = document.querySelector('.navbar-toggler.collapsed');
  if (toggleButton) {
    toggleButton.click();
  }
};

export const checkIsAnswerIsCorrect = (question: IQuestion, selected: number[]) => {
  const correctStatus = !question.correct.some((item: number) => !(selected.indexOf(item - 1) >= 0));
  return correctStatus ? selected.length === question.correct.length : correctStatus;
};

export const addAnswerToAnswerList = (userAnswer: IUserAnswer, answerId: number) => {
  const selectedAnswers: number[] = [...userAnswer.selected];

  if (selectedAnswers.indexOf(answerId) >= 0) {
    selectedAnswers.splice(selectedAnswers.indexOf(answerId), 1);
  } else {
    selectedAnswers.push(answerId);
  }
  return selectedAnswers;
};

export const getLanguageByLocation = (pathName: string) => {
  const languageRegexp = /^\/(en|de|it|fr)/;
  const locationLangaugeRes = languageRegexp.exec(pathName);
  const locationLanguage = locationLangaugeRes && locationLangaugeRes[1];
  return locationLanguage || defaultLanguage;
};

export const SSRHelmet = (pathName: string, routes) => {
  const languageRegexp = /^\/(en|de|it|fr)/;
  const defaultPage = 'MainPage';

  let correctRout = pathName;
  const language = getLanguageByLocation(pathName);

  if (correctRout.length > 1 && correctRout[correctRout.length - 1] === '/') {
    correctRout = correctRout.substring(0, correctRout.length - 1);
  }
  correctRout = correctRout.replace(languageRegexp, ``);

  if (correctRout === '') {
    correctRout = '/';
  }

  const { resources } = i18n.options;
  const route = routes.find(item => item.path === correctRout);
  if (language && route && route.name) {
    try {
      // @ts-ignore
      return resources![language].translation!.seo[route.name] || resources![language].translation!.sharing;
    } catch (e) {
      // @ts-ignore
      return resources![defaultLanguage].translation!.seo[defaultPage] || resources![language].translation!.sharing;
    }
  }
  // @ts-ignore
  return resources![defaultLanguage].translation!.seo[defaultPage] || resources![language].translation!.sharing;
};

export const imageAbsolutePath = (image: string) => {
  return `/static/images/${image}`;
};
