import React from 'react';
import { useTranslation } from 'react-i18next';
import { Jumbotron } from 'react-bootstrap';
import { IExamResultInfoProps } from './ExamResultInfo.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import Share from '../Share/Share';
import styles from './ExamResultInfo.module.css';

const ExamResultInfo: React.FC<IExamResultInfoProps> = ({ examResult, currentLanguage }: IExamResultInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.resultPageItem}>
      <PageTitle>{t('examResult.yourResult')}</PageTitle>
      <Jumbotron>
        <div className={styles.resultList}>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('examResult.point')}</span>
            <div className={styles.resultValue}>{`${examResult.truePointCount}/${examResult.pointCount}`}</div>
          </div>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('examResult.pointPercent')}</span>
            <div className={styles.resultValue}>
              {`${Math.round(+(examResult.truePointCount / examResult.pointCount).toFixed(2) * 100)}%`}
            </div>
          </div>
          <div className={styles.resultItem}>
            <span className={styles.resultLabel}>{t('examResult.time')}</span>
            <div className={styles.resultValue}>
              {`${((examResult.endTime - examResult.examStartTime) / 1000 / 60).toFixed(0)} min`}
            </div>
          </div>
        </div>
        <div className={styles.resultShare}>
          <div className={styles.resultShareTitle}>{t('examResult.share')}</div>
          <Share currentLanguage={currentLanguage} />
        </div>
      </Jumbotron>
    </div>
  );
};

export default ExamResultInfo;
