import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, GOOGLE_MAP_URL } from '../../constants';
import { IContainerMap, IMap } from './Map.types';
import styles from './Map.module.css';

export { default as mapStyles } from './mapStyles.json';

export const Map: React.FC<IMap> = props => <GoogleMap {...props} />;

const ComposedMap: React.ComponentClass<any> = withScriptjs(withGoogleMap(Map));

const ContainerMap: React.FC<IContainerMap> = props => <ComposedMap {...props} />;

ContainerMap.defaultProps = {
  googleMapURL: GOOGLE_MAP_URL,
  loadingElement: <div className={styles.loadingElement} />,
  containerElement: <div className={styles.container} />,
  mapElement: <div className={styles.map} />,
  defaultZoom: DEFAULT_MAP_ZOOM,
  defaultCenter: DEFAULT_MAP_CENTER
};

export default ContainerMap;
