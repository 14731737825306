import { put, takeEvery, takeLatest, call, all, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  GET_PROFILE_FROM_FIREBASE_REQUEST,
  GET_PROFILE_FROM_FIREBASE_FAILURE,
  GET_USER_EXAM_RESULTS_REQUEST,
  GET_USER_EXAM_RESULTS_SUCCESS,
  GET_USER_EXAM_RESULTS_FAILURE,
  GET_PROFILE_FROM_FIREBASE_SUCCESS,
  PLAN_EXTENSION_REQUEST,
  PLAN_EXTENSION_FAILURE,
  PLAN_EXTENSION_SUCCESS,
  SET_USER_LANGUAGE_REQUEST,
  SET_USER_LANGUAGE_FAILURE,
  SET_USER_LANGUAGE_SUCCESS
} from '../actions/user.action';
import { getLastExams, getAllExams } from './exam.api.fb';
import { getFirebaseProfile, getProfile, userPlanExtension, setUserLanguageApi } from './user.api';
import { firebaseApp } from '../config/firebase.config';
import { SET_IS_LOGGED } from '../actions/login.action';
import { getFBCategoryName } from '../utils/utils';
import { LANGUAGE_KEY } from '../constants/storageKeys';
import { COOKIES_EXPIRES } from '../constants';
import { selectLanguage } from '../reducer/user.selectors';
import { selectIsOfflineMode } from '../reducer/auth.selectors';

function* getUserExamInfo({ payload }) {
  try {
    const { currentCategory } = payload;
    const categoryForQuery = getFBCategoryName(currentCategory);
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;

    const lastExamsInfo = yield getLastExams(userId, categoryForQuery);

    const sortedbyCorrectAnswers = yield getAllExams(userId, categoryForQuery);

    yield put(
      GET_USER_EXAM_RESULTS_SUCCESS({
        lastExams: lastExamsInfo,
        bestResult: sortedbyCorrectAnswers
      })
    );
  } catch (e) {
    yield put(GET_USER_EXAM_RESULTS_FAILURE(e.message));
  }
}

function* getUserProfile() {
  try {
    const response = yield getProfile();
    if (response.status !== 200) {
      yield put(GET_USER_PROFILE_FAILURE(response.payload.message));
      yield put(
        SET_IS_LOGGED({
          isLogged: false
        })
      );
    } else {
      yield put(GET_USER_PROFILE_SUCCESS(response.payload.customer));
      yield put(
        SET_IS_LOGGED({
          isLogged: true
        })
      );
    }
  } catch (e) {
    yield put(GET_USER_PROFILE_FAILURE(e.message));
    yield put(
      SET_IS_LOGGED({
        isLogged: false
      })
    );
  }
}

function* getFirebaseUserProfile(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;
    if (userId) {
      const profile = yield getFirebaseProfile(userId);

      if (profile) {
        yield put(GET_PROFILE_FROM_FIREBASE_SUCCESS(profile));
        yield put(
          SET_IS_LOGGED({
            isLogged: true
          })
        );

        if (action.payload.isOnline) {
          yield put(GET_USER_PROFILE_REQUEST());
        }
      } else {
        yield put(GET_PROFILE_FROM_FIREBASE_FAILURE('Empty profile!'));
      }
    } else {
      const language = Cookies.get(LANGUAGE_KEY);

      yield put(GET_PROFILE_FROM_FIREBASE_FAILURE('Empty user!'));

      if (language) {
        yield put(SET_USER_LANGUAGE_SUCCESS({ language }));
      }
    }
  } catch (e) {
    yield put(GET_PROFILE_FROM_FIREBASE_FAILURE(e.message));
    yield put(
      SET_IS_LOGGED({
        isLogged: false
      })
    );
  }
}

function* setUserLanguage({ payload }) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : null;
  const currentLanguage = yield select(selectLanguage);
  Cookies.set(LANGUAGE_KEY, payload.language, { expires: COOKIES_EXPIRES });

  // currentLanguage !== payload.language - minimize requests to api server
  // userId - if not found, save language localy
  if (userId && currentLanguage !== payload.language) {
    try {
      const isOffline = yield select(selectIsOfflineMode);
      if (!isOffline) {
        const response = yield call(setUserLanguageApi, payload.language);
        if (response.status !== 200) {
          yield put(SET_USER_LANGUAGE_FAILURE(response.payload.message));

          // we need to change language even if we get an error in saving language in the back-end
          yield put(SET_USER_LANGUAGE_SUCCESS({ language: payload.language }));
        } else {
          yield put(SET_USER_LANGUAGE_SUCCESS(payload));
        }
      }
    } catch (e) {
      yield put(SET_USER_LANGUAGE_FAILURE(e));
    }
  } else {
    yield put(SET_USER_LANGUAGE_SUCCESS(payload));
  }
}

function* makePlanExtension({ payload }) {
  try {
    const response = yield call(userPlanExtension, payload);
    if (response.status !== 200) {
      yield put(PLAN_EXTENSION_FAILURE(response.payload.message));
    } else {
      yield put(PLAN_EXTENSION_SUCCESS());
      window.location.href = response.payload.payment.html || '/';
    }
  } catch (e) {
    yield put(PLAN_EXTENSION_FAILURE(e));
  }
}

function* userSaga() {
  yield all([
    takeEvery(GET_USER_PROFILE_REQUEST, getUserProfile),
    takeEvery(GET_PROFILE_FROM_FIREBASE_REQUEST, getFirebaseUserProfile),
    takeEvery(GET_USER_EXAM_RESULTS_REQUEST, getUserExamInfo),
    takeEvery(SET_USER_LANGUAGE_REQUEST, setUserLanguage),
    takeLatest(PLAN_EXTENSION_REQUEST, makePlanExtension)
  ]);
}

export default userSaga;
