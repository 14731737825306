import React, { MouseEvent, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { selectLanguage } from '../../reducer/user.selectors';
import { scrollToAnchor } from '../../utils/utils';

export const LinkWrapper: React.FC<LinkProps> = memo(props => {
  const { location } = useReactRouter();
  const language = useSelector(selectLanguage);

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event);
    } else {
      event.preventDefault();
    }
    if (typeof props.to === 'string') {
      scrollToAnchor(props.to);
    }
  };

  if (typeof props.to === 'string' && props.to.startsWith('#')) {
    return <Link {...props} to={{ ...location, hash: props.to }} onClick={handleOnClick} />;
  }

  return <Link {...props} to={`/${language}${props.to}`} />;
});
