import { isSSR } from './utils/utils';

export const webViewList = ['webview=ios', 'webview=android'];

export const CTM_ADLER_LOCATION = { lat: 47.2023249, lng: 7.5277035 };
export const DEFAULT_MAP_CENTER = { lat: 46.82, lng: 8 };
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
export const DEFAULT_MAP_ZOOM = 8;
export const DEMO_PATH_LIST = [
  '/mycockpitdemo',
  '/questions',
  '/theory',
  '/exam',
  '/3dteacher',
  '/ebike',
  '/questions/:category/:topic/:id?'
];
export const HOST = process.env.REACT_APP_API_GATE_HOST || isSSR() ? undefined : window.location.hostname;
export const PORT = process.env.REACT_APP_API_GATE_PORT ? `:${process.env.REACT_APP_API_GATE_PORT}` : '';
export const GATE = `${isSSR() ? undefined : window.location.protocol}//${HOST}${PORT}/api/v1`;
export const COOKIES_EXPIRES = 7; // 7 days
export const WHATS_APP_LINK = 'https://wa.me/41793162890';
export const IS_WEBVIEW = isSSR() ? false : webViewList.some(item => window.location.href.indexOf(item) !== -1);
export const IS_WEBVIEW_IOS = isSSR() ? undefined : window.location.href.indexOf(webViewList[0]) !== -1;
export const IS_CONTAIN_WEBVIEW_IN_NAVIGATOR = isSSR() ? undefined : navigator.userAgent.indexOf('WebView') !== -1;
/* eslint-disable-next-line */
export const IOS_VERSION = isSSR()
  ? undefined
  : navigator.userAgent.split('iOS')[1]
  ? navigator.userAgent
      .split('iOS')[1]
      .split('.')[0]
      .trim()
  : false;
