import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../utils/utils';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './Footer.module.css';

const footerLogo = imageAbsolutePath('footer-logo.svg');
const brandFirst = imageAbsolutePath('brand-1.svg');
const brandSecond = imageAbsolutePath('brand-2.png');
const brandThree = imageAbsolutePath('brand-3.svg');
const brandFour = imageAbsolutePath('brand-4.png');

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={3}>
            <LinkWrapper className={styles.footerLogo} to="/">
              <img className="responsive-img" src={footerLogo} alt="footer-logo" />
            </LinkWrapper>

            <ul className={styles.socialLinkList}>
              <li>
                <a
                  className={styles.socialLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ctmalder/"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/ctm_alder_ag/?hl=de"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('info.emailhref')}
                >
                  <i className="icon-email" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
                  <i className="icon-whatsapp" />
                </a>
              </li>
              <li>
                <LinkWrapper to="/contacts" className={styles.socialLinkItem}>
                  <i className="icon-support" />
                </LinkWrapper>
              </li>
            </ul>
          </Col>
          <Col lg={5}>
            <div className={styles.footerTextWrapper}>
              <div className={styles.footerText}>
                <span className={styles.footerTextItem}>{t('footer.CTMAlderAG')}</span>
                <span className={styles.footerTextItem}>{t('footer.GlutzBlotzheimStrasse3')}</span>
                <span className={styles.footerTextItem}>{t('footer.CH4500Solothurn')}</span>
              </div>
              <div className={styles.footerText}>
                {t('info.tellabel')}
                {' · '}
                {t('footer.web')}
              </div>
              <div className={styles.footerCopyright}>
                {`© ${new Date().getFullYear()} `}
                copyright by CTM Alder AG
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                  {t('footer.TOC')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                  {t('footer.imprint')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                  {t('footer.asa')}
                </LinkWrapper>
                {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <ul className={styles.brandLinkList}>
              <li>
                <a
                  className={styles.brandLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.mobiliar.ch/versicherungen-und-vorsorge/angebote-fuer-junge-bis-26-jahre"
                >
                  <img src={brandFirst} width={94} height={14} alt="mobiliar" />
                </a>
              </li>
              <li>
                <a
                  className={styles.brandLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('links.qualidrive')}
                >
                  <img src={brandSecond} width={80} height={26} alt="qualidrive" />
                </a>
              </li>
              <li>
                <a
                  className={styles.brandLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.fahrschultheorie.ch"
                >
                  <img src={brandThree} width={67} height={22} alt="fahrschultheorie" />
                </a>
              </li>
              <li>
                <a
                  className={styles.brandLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://routinier24.ch?utm_campaign=Theorie24&utm_source=footer&utm_medium=link"
                >
                  <img src={brandFour} width={84} height={25} alt="routinier24.ch" />
                </a>
              </li>
            </ul>
          </Col>
          <Col sm={12}>
            <div className={styles.footerCopyrightMobile}>
              {`© ${new Date().getFullYear()} `}
              copyright by CTM Alder AG
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                {t('footer.TOC')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                {t('footer.imprint')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                {t('footer.asa')}
              </LinkWrapper>
              {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
