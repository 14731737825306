import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './VipCard.module.css';

const logo = imageAbsolutePath('logo.svg');

const VipCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.vipCard}>
      <img className={styles.cardLogo} src={logo} alt="logo" />
      <p className={styles.cardTitle}>{t('homePage.vipCard.vipCard')}</p>
      <div className={styles.ruleList}>
        <div className={styles.ruleListItem}>
          <i className="icon-car" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-motorcycle" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-tractor" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-e-bike" />
        </div>
      </div>
      <LinkWrapper className="linkBtn" to="/vipcode">
        {t('homePage.btn.redeemCode')}
        <i className="icon-arrow" />
      </LinkWrapper>
    </div>
  );
};

export default VipCard;
