import React from 'react';
import { IMarketProps } from './Market.types';
import styles from './Market.module.css';

const Market: React.FC<IMarketProps> = ({ src, alt }: IMarketProps) => (
  <a
    className={styles.storeBtnItem}
    rel="noopener noreferrer"
    target="_blank"
    href={
      alt === 'google'
        ? 'https://play.google.com/store/apps/details?id=com.theorie24.app'
        : 'https://apps.apple.com/ch/app/theorie24-ch-theoriepr%C3%BCfung/id464801879'
    }
  >
    <img src={src} alt={alt} />
  </a>
);

export default Market;
