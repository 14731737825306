import { FC, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { IRootState } from '../../reducer';
import { GET_PROFILE_FROM_FIREBASE_REQUEST } from '../../actions/user.action';
import { UNCALLED, FAILURE } from '../../constants/store.constants';
import { GET_TOKEN_REQUEST, FIREBASE_AUTH_REQUEST } from '../../actions/auth.action';
import { firebaseApp } from '../../config/firebase.config';
import { Request } from '../../reducer/fetch.types';
import { ILoginContainerProps } from './LoginContainer.types';
import { LANGUAGE_KEY } from '../../constants/storageKeys';

export const LoginContainer: FC<ILoginContainerProps> = memo(function LoginContainerMemo(props: ILoginContainerProps) {
  const [firebaseConnected, setFirebaseConnected] = useState(false);
  const { isOnline, history } = props;
  const dispatch = useDispatch();
  const userFbRequest: Request = useSelector(({ user }: IRootState) => user.userFbRequest);
  const loginToken: string = useSelector(({ auth }: IRootState) => auth.token);
  const deviceChecked: boolean = useSelector(({ auth }: IRootState) => auth.deviceChecked);
  const firebaseAuth: boolean = useSelector(({ auth }: IRootState) => auth.firebaseAuth);
  const firebaseToken: string = useSelector(({ auth }: IRootState) => auth.firebaseToken);
  const getTokenRequest: Request = useSelector(({ auth }: IRootState) => auth.getTokenRequest);

  useEffect(() => {
    if (getTokenRequest.status === UNCALLED) {
      dispatch(GET_TOKEN_REQUEST());
    }
  }, [dispatch, getTokenRequest.status]);

  useEffect(() => {
    if (firebaseToken && firebaseConnected) {
      dispatch(FIREBASE_AUTH_REQUEST());
    }
  }, [dispatch, firebaseToken, firebaseConnected]);

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(() => {
      setFirebaseConnected(true);
    });
  }, []);

  useEffect(() => {
    if (loginToken && deviceChecked && firebaseAuth && firebaseConnected && userFbRequest.status === UNCALLED) {
      dispatch(GET_PROFILE_FROM_FIREBASE_REQUEST({ isOnline }));
    }
  }, [dispatch, loginToken, deviceChecked, firebaseAuth, firebaseConnected, userFbRequest, isOnline]);

  useEffect(() => {
    if (getTokenRequest.status === FAILURE) {
      const language = Cookies.get(LANGUAGE_KEY);
      const { location } = history;
      const languageRegexp = /^\/(en|de|it|fr)/;
      const locationLangaugeRes = languageRegexp.exec(location.pathname);
      const locationLanguage = locationLangaugeRes && locationLangaugeRes[1];

      if (language && locationLanguage !== language) {
        history.push({
          search: location.search,
          hash: location.hash,
          state: location.state,
          pathname: languageRegexp.test(location.pathname)
            ? location.pathname.replace(languageRegexp, `/${language}`)
            : `/${language}${location.pathname}`
        });
      }
    }
  }, [getTokenRequest.status, history]);

  return null;
});
